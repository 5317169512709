<template>
  <div class="table-bar">
    <el-button @click="uploadfile" size="mini" type="primary" class="uploadbtn">
      上传文件
    </el-button>
     <!-- <el-button id="download" @click="download" size="mini" type="primary" class="uploadbtn">
      下载视频转换工具
    </el-button>-->
     <el-button @click="renewlist" size="mini" type="primary" class="refreshbtn">
      刷新
    </el-button>
    <el-link href="https://wumitech-app-market-gd.cn-gd.ufileos.com/wm-tool.zip" type="primary" class="download">下载视频转换工具</el-link>

  </div>
</template>
<script>
export default {
  props: {
    region: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    uploadfile() {
      this.$emit("uploadfile");
    },
    download() {
      this.$emit("download");
    },
    renewlist() {
      this.$emit("getufilelist");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>