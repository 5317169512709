<template>
  <div class="live-wrap">
    <div class="bread-wrap">
      <i class="iconfont icon-shouji"></i
      ><span class="bread-title">云手机直播配置</span>
      <span class="bread-brand"> {{ phonename }}</span>
      <el-button
        @click="showliveguidemodel"
        class="liveguidebtn"
        type="primary"
        size="mini"
        >直播指引</el-button
      >
    </div>
    <div class="content">
      <div class="content-left">
        <!-- <div class="left-title"></div> -->
        <div class="phone-wrap" ref="phonewrap">
          <div
            v-loading="loading"
            :element-loading-text="loadingtext"
            element-loading-spinner="el-icon-loading"
            element-loading-background="transparent"
            ref="cvideo"
            class="video-wrap"
          >
            <i
              v-if="rtc == null && modetype != 3"
              @click="showcreatemodel"
              class="iconfont icon-tianjia"
            ></i>
            <p class="titlewarning" v-if="modetype == 3">
              云手机操作页面查看无人矩阵直播云手机
            </p>
            <video
              id="remote-video"
              autoplay
              playsinline
              webkit-playsinline
              ref="revideo"
              style="width: 100%; height: 100%"
              @contextmenu.prevent
              @mousedown="mousedown"
              @mouseup="mouseup"
              @mousemove="mousemove"
              @mouseout="mouseout"
              @mousewheel="mousewheel"
            ></video>
          </div>
          <div class="setting-area">
            <ul class="setting-ul">
              <li @click="addvolume">
                <span
                  ><i class="iconfont icon-a-5Elabayinliangfangda"></i
                ></span>
              </li>
              <li @click="devolume">
                <span
                  ><i class="iconfont icon-a-5Dlabayinliangjianxiao"></i
                ></span>
              </li>
              <!-- <li @click="changerotate">
                <span><i class="iconfont icon-xuanzhuan"></i></span>
              </li> -->
              <el-popconfirm
                @confirm="phonemenu"
                title="加速会退出所有APP进程,确定要继续吗？"
              >
                <li slot="reference">
                  <i class="iconfont icon-jiasu"></i>
                </li>
              </el-popconfirm>
              <li @click="backhome">
                <span><i class="iconfont icon-home"></i></span>
              </li>
              <li @click="backpage">
                <span><i class="iconfont icon-fanhui"></i></span>
              </li>
              <!-- <el-popconfirm @confirm="allrestart" title="确认重启吗?">
                <li slot="reference">
                  <span><i class="iconfont icon-zhongqi"></i></span>
                </li>
              </el-popconfirm> -->
              <el-popconfirm
                @confirm="hangup"
                :title="`${
                  modetype == 2
                    ? '断开主显示会结束矩阵直播,确定断开吗?'
                    : '确认断开吗?'
                }`"
              >
                <li slot="reference">
                  <span><i class="iconfont icon-qidong"></i></span>
                </li>
              </el-popconfirm>
            </ul>
          </div>
          <!-- <div class="setting-bottom">
            <ul class="bottom-ul">
              <li @click="phonemenu">
                <i class="iconfont icon-jiasu"></i>
              </li>
              <li @click="backhome">
                <span><i class="iconfont icon-home"></i></span>
              </li>
              <li @click="backpage">
                <span><i class="iconfont icon-fanhui"></i></span>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
      <div class="content-right">
        <live-mode @changemode="changemode" :modetype="modetype" />
        <live-step
          ref="livestep"
          :mainconnect="mainconnect"
          :stopmainlive="stopmainlive"
          :modetype="modetype"
          :query="query"
          @startunmannedlive="startunmannedlive"
          @stopunmannedlive="stopunmannedlive"
          @startrtmpurl="startrtmpurl"
          @uploadfile="uploadfile"
          @deletefile="deletefile"
        />
      </div>
    </div>
    <create-live
      title="创建主显示直播窗"
      :query="query"
      :showcreatelive="showcreatelive"
      @closeshowcreate="closeshowcreate"
      @confirmcreatelive="confirmcreatelive"
    />
    <live-guide
      title="直播指引"
      :isliveguideshow="isliveguideshow"
      @closeliveguidemodel="closeliveguidemodel"
    />
    <up-load-model
      :showuploadmodel="showuploadmodel"
      @closeshowupload="closeshowupload"
      title="上传至存储空间"
      :region="region"
    />
    <delete-model
      :showdeletemodel="showdeletemodel"
      @closeshowdelete="closeshowdelete"
      title="删除文件"
      :region="region"
      :row="row"
      :needfooter="true"
    />
  </div>
</template>
<script>
import ucloudrtcController from "@/sdk/groupcontroller";
import transKeyCode from "@/utils/transkeycode";
import LiveMode from "./LiveMode/index.vue";
import LiveStep from "./LiveStep/index.vue";
import CreateLive from "./Models/CreateLive.vue";
import LiveGuide from "./LiveGuide/index.vue";
import { getsession } from "@/utils/auth";
import { liveNotice } from "@/utils/public";
import UpLoadModel from "../StorageFiles/Models/UpLoadModel.vue";
import DeleteModel from "../StorageFiles/Models/DeleteModel.vue";

export default {
  data() {
    return {
      row: {},
      showdeletemodel: false,
      showuploadmodel: false,
      region: "hk",
      isliveguideshow: false,
      query: {},
      rtc: null,
      phoneId: "",
      phonename: "",
      loadingParams: {
        Id: "",
        mediaConstraints: {
          // video: true,
          // audio: true,
          live: true,
          usecamera: false,
          usemicrophone: false,
        },
      },
      loading: false,
      loadingtext: "连接中",
      count: 0,
      mdown: false,
      showcreatelive: false,
      modetype: 4,
      stopmainlive: false,
      mainconnect: false,
      unmannedurl: "",
      rotate: "portrait",
      phonewrapheight: "",
      ratio: "1.7777777",
    };
  },
  components: {
    LiveMode,
    LiveStep,
    CreateLive,
    LiveGuide,
    UpLoadModel,
    DeleteModel,
  },
  activated() {
    if (this.rtc) {
      this.$refs.revideo.play();
    }
  },
  watch: {
    phonewrapheight(val) {
      this.$refs.phonewrap.style.width =
        this.phonewrapheight / this.ratio + 35 + "px";
      console.log(val);
    },
  },
  methods: {
    showliveguidemodel() {
      window.open(liveNotice);
    },
    closeliveguidemodel() {
      this.isliveguideshow = !this.isliveguideshow;
    },
    changemode(mode) {
      if (this.rtc) {
        this.$message.warning("直播云手机已连接，请勿切换直播模式");
        return;
      }
      if (this.$store.getters.unmannedlivelist.length) {
        this.$message.warning("无人直播中请勿切换直播模式");
        return;
      }
      this.modetype = mode;
      console.log(this.modetype);
    },
    showcreatemodel() {
      this.showcreatelive = !this.showcreatelive;
    },
    closeshowcreate() {
      this.showcreatelive = !this.showcreatelive;
    },
    hangup() {
      if (this.modetype == 2) {
        this.mainconnect = false;
        this.stopmainlive = true;
        if (this.rtc) {
          this.rtc.stopsuqarlive();
        }
      }
      if (this.modetype == 4) {
        // if (this.rtc && this.unmannedurl) {
        //   this.rtc.stopunmanedlive();
        // }
        this.$store.commit("group/setobsrtmpurl", {
          obsrtmpurl: "",
        });
        this.mainconnect = false;
        this.stopmainlive = true;
        this.unmannedurl = "";
      }
      if (this.rtc) {
        this.rtc.Hangup();
      }
      this.rtc = null;
      this.loading = false;
      this.phoneId = "";
      this.phonename = "";
    },
    allrestart() {
      this.rtc.closeConnection();
      let data = {
        CityId: this.query.city_id,
        ProjectId: this.query.project_id,
        Action: "RebootUPhone",
        ProductType: this.query.product_type,
      };
      data["UPhoneIds.0"] = this.phoneId;
      this.$store.dispatch("group/phonereboot", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.getUPhoneJob(res.JobId);
        } else {
          this.$message.error("重启失败,请重试！");
          this.init();
        }
      });
    },
    getUPhoneJob(jobId) {
      if (this.timmer !== null) {
        clearInterval(this.timmer);
      }
      let data = {
        Action: "DescribeUPhoneJob",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        JobIds: [jobId],
        ProductType: this.query.product_type,
      };
      this.timmer = setInterval(() => {
        this.$store
          .dispatch("group/phonedescribejob", { ...data })
          .then((res) => {
            if (res.Jobs[0].State == "SUCCESS") {
              clearInterval(this.timmer);
              this.init();
            }
            if (res.Jobs[0].State == "FAILED") {
              this.$message.error("重启失败！");
              clearInterval(this.timmer);
              this.init();
            }
            if (res.Jobs[0].State == "PARTIAL_SUCCESS") {
              this.$message.error("重启失败！");
              clearInterval(this.timmer);
              this.init();
            }
          });
      }, 5000);
    },
    addvolume() {
      let message = {
        name: "addvolume",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    devolume() {
      let message = {
        name: "devolume",
      };
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    changerotate() {
      if (this.rotate == "portrait") {
        this.rotate = "landscape";
        console.log(this.rotate);
        return;
      }
      if (this.rotate == "landscape") {
        this.rotate = "portrait";
        console.log(this.rotate);
        return;
      }
    },
    backhome() {
      return new Promise((resolve, reject) => {
        let message = {
          name: "backhome",
        };
        this.sendmsg(message);
        resolve();
      });
    },
    phonemenu() {
      this.backhome().then(() => {
        setTimeout(() => {
          let message = {
            name: "phonemenu",
          };
          this.sendmsg(message);
        }, 500);
      });
    },
    backpage() {
      let message = {
        name: "backlast",
      };
      this.sendmsg(message);
    },
    confirmcreatelive(phonelist) {
      this.loadingParams.Id = phonelist[0].phoneId;
      this.phoneId = phonelist[0].phoneId;
      this.phonename = phonelist[0].uphoneName;
      if (this.modetype == 4) {
        this.loadingParams.mediaConstraints.live = false;
        this.loadingParams.mediaConstraints.video = true;
        this.loadingParams.mediaConstraints.audio = true;
        this.loadingParams.mediaConstraints.usecamera = false;
        this.loadingParams.mediaConstraints.usemicrophone = false;
      } else {
        this.loadingParams.mediaConstraints.live = true;
        this.loadingParams.mediaConstraints.video = false;
        this.loadingParams.mediaConstraints.audio = false;
        this.loadingParams.mediaConstraints.usecamera = true;
        this.loadingParams.mediaConstraints.usemicrophone = true;
      }
      this.init();
    },
    init() {
      this.rtc = new ucloudrtcController(this.loadingParams);
      this.startconnection();
    },
    startconnection() {
      var this_ = this;
      this.rtc.startConnection();
      this.rtc.onstatus("devicestatus", (states) => {
        if (states == 500) {
          this.content = "强制连接中";
          this.isresult = false;
          this.restart();
        }
        if (states == 136) {
          // this.icestatus = "disconnected";
          this.rtc.Hangup();
          this.rtc = null;
          this.phonename = "";
          return;
        }
        switch (states) {
          case 1001:
            this.loading = true;
            this.loadingtext = `网络连接错误${1001}`;
            break;
          case 1003:
            this.loading = true;
            this.loadingtext = `设备已关机${1003}`;
            break;
          case 1008:
            this.loading = true;
            this.loadingtext = `服务器未响应${1008}`;
            break;
          case 1026:
            this.loading = true;
            ++this.count;
            if (this.count == 4) {
              this.forceconnect();
              this.loading = false;
              return;
            }
            break;
          case 1027:
            this.loading = true;
            this.loadingtext = `网络连接错误${1027}`;
            break;
          case 1028:
            this.loading = true;
            this.loadingtext = `网络连接错误${1028}`;
            break;
          case 1031:
            this.loading = true;
            this.loadingtext = `网络连接错误${1031}`;
            break;
          case 1038:
            this.loading = true;
            this.loadingtext = "连接中";
            break;
          case 73002:
            this.loading = true;
            this.loadingtext = `设备未启动${73002}`;
            break;
          case 42100:
            this.loading = true;
            this.loadingtext = `请求参数错误${42100}`;
            break;
          case 50000:
            this.loading = true;
            this.loadingtext = `服务器内部错误${50000}`;
            break;
          case 90010:
            this.loading = true;
            this.loadingtext = `网络连接错误${90010}`;
            break;
          case 90011:
            this.loading = true;
            this.loadingtext = `网络连接错误${90011}`;
            break;
          case 90012:
            this.loading = true;
            this.loadingtext = `网络连接错误${90012}`;
            break;
          case 90013:
            this.loading = true;
            this.loadingtext = `网络连接错误${90013}`;
            break;
          default:
            this.loading = true;
            this.loadingtext = "网络连接错误";
            break;
        }
        this.restart();
      });
      this.rtc.onstatus("networkstatus", (states) => {
        switch (states) {
          case "connected":
            this.mainconnect = true;
            this.stopmainlive = false;
            this.loading = false;
            this.getremotestream();
            break;
          case "new":
            this.loading = true;
            this.loadingtext = "网络连接中";
            break;
          case "checking":
            this.loading = true;
            this.loadingtext = "网络连接中";
            break;
          case "failed":
            this.loading = true;
            this.loadingtext = "网络连接中";
            this.restart();
            break;
          case "disconnected":
            this.loading = true;
            this.loadingtext = "网络连接断开";
            this.restart();
            break;
        }
      });
      this.rtc.onstatus("livestatus", (states) => {
        switch (states) {
          case "readylive":
            this.rtc.startlive(this_.modetype);
            console.log(states);
            break;
          case "stoplive":
            this.rtc.stoplive(this_.modetype);
            console.log(states);
            break;
        }
      });
      this.rtc.onstatus("channelstatus", (states) => {
        switch (states) {
          case "open":
            if (this.modetype == 2) {
              this.rtc.stopunmanedlive();
              this.rtc.startsuqarlive();
            }
            if (this.modetype == 4 && this.unmannedurl) {
              this.rtc.startunmanedlive(this.unmannedurl);
            }
            if (this.modetype == 1) {
              this.rtc.stopunmanedlive();
            }
            break;
          case "close":
            console.log("close");
            break;
        }
      });
      this.rtc.onstatus("livertmp", (url) => {
        this.$store.commit("group/setrtpmurl", { rtmpurl: url });
        console.log(url);
      });
      this.rtc.onstatus("obsrtmp", (url) => {
        this.$store.commit("group/setobsrtmpurl", {
          obsrtmpurl: url,
        });
      });
    },
    getremotestream() {
      var _this = this;
      var remotestream = this.rtc.getremotestream();
      this.$refs.revideo.srcObject = remotestream; // 添加视频流到标签
      this.$refs.revideo.onloadedmetadata = function onloadedmetadata() {
        console.log(this.videoHeight, this.videoWidth);
        if (this.videoHeight / this.videoWidth !== 1) {
          let ratio = this.videoHeight / this.videoWidth;
          _this.ratio = ratio;
          // _this.$refs.cvideo.style.height = 360 * ratio + "px";
          _this.$refs.phonewrap.style.width =
            this.phonewrapheight / ratio + 35 + "px";
        }
      };
    },
    forceconnect() {
      if (this.rtc) {
        this.rtc.forceconnent();
      }
      //console.log("发送offer");
    },
    //重新连接
    restart() {
      if (this.rtc != null) {
        console.log("窗口重连");
        this.rtc.reStart();
      }
    },
    //开启真人矩阵直播
    startsuqarlive() {
      if (this.modetype == 2) {
        console.log("发送rtmp");
        this.rtc.startsuqarlive();
      }
    },
    //开启无人直播
    startunmannedlive(url) {
      this.unmannedurl = url;
      this.rtc.startunmanedlive(url);
    },
    //结束无人直播
    stopunmannedlive() {
      this.unmannedurl = "";
      if (this.rtc) {
        this.rtc.stopunmanedlive();
      }
    },
    //获取rtmpurl
    startrtmpurl() {
      if (this.rtc) {
        this.rtc.startrtmpurl();
      }
    },
    uploadfile() {
      this.showuploadmodel = !this.showuploadmodel;
    },
    closeshowupload() {
      this.$refs.livestep.getufilelist();
      this.showuploadmodel = !this.showuploadmodel;
    },
    deletefile(row) {
      this.row = row;
      this.showdeletemodel = !this.showdeletemodel;
    },
    closeshowdelete() {
      this.$refs.livestep.getufilelist();
      this.showdeletemodel = !this.showdeletemodel;
    },

    //鼠标落下
    mousedown(e) {
      let message = {
        name: "",
        // offsetWidth: 300,
        // offsetHeight: parseInt(this.height),
        button: e.button,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        timeStamp: e.timeStamp,
      };
      if (e.button == 0) {
        this.mdown = true;
        message.name = "touchstart";
      } else if (e.button == 1) {
        message.name = "wheeldown";
        message.wheelDelta = "0x01000073";
      } else if (e.button == 2) {
        message.name = "touchrightstart";
        message.wheelDelta = "0x01000075";
      }

      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //鼠标抬起
    mouseup(e) {
      // console.log(e)
      let message = {
        name: "",
        // offsetWidth: 300,
        // offsetHeight: parseInt(this.height),
        button: e.button,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        timeStamp: e.timeStamp,
      };
      if (e.button == 0) {
        if (this.mdown) {
          this.mdown = false;
          message.name = "touchend";
          if (this.rtc) {
            this.sendmsg(message);
          }
        }
      } else if (e.button == 1) {
        message.name = "wheelup";
        message.wheelDelta = "0x01000073";
        if (this.rtc) {
          this.sendmsg(message);
        }
      } else if (e.button == 2) {
        message.name = "touchrightend";
        message.wheelDelta = "0x01000075";
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    //鼠标移出
    mouseout(e) {
      // console.log(e.type);
      // console.log(e);
      if (this.mdown) {
        this.mdown = false;
        let message = {
          name: "touchend",
          // offsetWidth: 300,
          // offsetHeight: parseInt(this.height),
          button: e.button,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
          timeStamp: e.timeStamp,
        };
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    mousewheel(e) {
      e.preventDefault();
      let msg = {
        name: "mousewheel",
        wheelDelta: "",
        timeStamp: e.timeStamp,
      };
      if (e.wheelDelta < 0) {
        msg.wheelDelta = -1; // 滚轮向上
      } else if (e.wheelDelta > 0) {
        msg.wheelDelta = 1; // 滚轮向下
      }
      if (this.rtc) {
        this.sendmsg(msg);
      }
      console.log(e);
    },
    //鼠标移动
    mousemove(e) {
      // console.log(e.type);
      if (this.mdown) {
        let message = {
          name: "touchmove",
          // offsetWidth: 300,
          // offsetHeight: parseInt(this.height),
          button: e.button,
          offsetX: e.offsetX,
          offsetY: e.offsetY,
          timeStamp: e.timeStamp,
        };
        if (this.rtc) {
          this.sendmsg(message);
        }
      }
    },
    //信息交互(发送消息到服务器)
    sendmsg(message) {
      if (this.rtc) {
        this.rtc.sendMessage(message);
      }
    },
    //键盘按下
    onkeydown(e) {
      // console.log(e);
      var message = {
        name: "keydown",
        keyCode: transKeyCode(e.keyCode),
        timeStamp: e.timeStamp,
      };
      //CRTL+V Pressed
      if ((e.ctrlKey || e.metaKey) && e.keyCode == 86) {
        message.name = "clipboard";
        console.log(message);
      }
      if (e.keyCode == 91 || e.keyCode == 18 || e.keyCode == 9) {
        e.preventDefault();
        return;
      }
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    //键盘抬起
    onkeyup(e) {
      let message = {
        name: "keyup",
        keyCode: transKeyCode(e.keyCode),
        timeStamp: e.timeStamp,
      };
      if (e.keyCode == 91 || e.keyCode == 18 || e.keyCode == 9) {
        e.preventDefault();
        return;
      }
      if (this.rtc) {
        this.sendmsg(message);
      }
    },
    resizeview() {
      var phonewrap = document.getElementsByClassName("phone-wrap");
      if (phonewrap.length) {
        this.phonewrapheight = phonewrap[0].offsetHeight;
      }
      // console.log(phonewrap[0].offsetHeight);
    },
  },
  mounted() {
    var _this = this;
    this.resizeview();
    window.addEventListener("resize", _this.resizeview);
    this.query = {
      project_id: getsession("project_id"),
      city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
    // this.init();
    //全局监听键盘事件
    document.addEventListener("keydown", _this.onkeydown);
    document.addEventListener("keyup", _this.onkeyup);
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>