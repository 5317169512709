<template>
  <div class="unmanned-wrap">
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤1</p>
        <p class="step-content">左侧选择直播版云手机</p>
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤2</p>
        <el-tabs v-model="activeName">
          <el-tab-pane label="OBS推流" name="rtmp">
            <div class="rtmpcontent">
              <el-row>
                <el-col :span="3">
                  <span style="font-size: 13px">服务器:</span>
                </el-col>
                <el-col :span="14">
                  <el-input
                    :disabled="true"
                    v-model="rtmpurl"
                    size="mini"
                    placeholder="RTMP服务器地址"
                  >
                    <el-button
                      v-clipboard:copy="rtmpurl"
                      v-clipboard:success="copyurl"
                      v-clipboard:error="copyerror"
                      slot="append"
                    >
                      <i class="el-icon-document-copy"></i>
                    </el-button>
                  </el-input>
                </el-col>
                <el-col :span="6">
                  <el-button
                    :loading="urlloading"
                    @click="getrtmpurl"
                    class="rtmpbtn"
                    size="mini"
                    type="primary"
                    >获取地址</el-button
                  >
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="3">
                  <span style="font-size: 13px">推流码:</span>
                </el-col>
                <el-col :span="14">
                  <el-input
                    :disabled="true"
                    v-model="rtmpcode"
                    size="mini"
                    placeholder="RTMP推流码"
                  >
                    <el-button
                      v-clipboard:copy="rtmpcode"
                      v-clipboard:success="copyurl"
                      v-clipboard:error="copyerror"
                      slot="append"
                    >
                      <i class="el-icon-document-copy"></i>
                    </el-button>
                  </el-input>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div class="contentmsg">
                    建议参数： 720p或1080p; 码率3-5Mbps; 30帧（暂不支持60帧）
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="文件推流" name="file">
            <p class="step-content">点击上传文件按钮，选择直播文件，开始推流</p>
            <p class="step-warning">注：开始推流前请不要提前开启App直播</p>
            <p class="step-warning">建议参数： 720p或1080p; 码率3-5Mbps; 30帧（暂不支持60帧）</p>
            <table-bar
              :region="region"
              @getufilelist="getufilelist"
              @uploadfile="uploadfile"
              @download="download"
            />
            <com-table
              v-loading="loading"
              class="filestoragelist"
              :headercellstyle="headercellstyle"
              :columns="filestoragelist"
              :pagination="pagination"
              :data="tabledata"
              tableheight="300"
            >
              <el-table-column
                align="center"
                header-align="center"
                slot="action"
                label="操作"
                min-width="150"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="startlive(scope.row)"
                    :disabled="scope.row.islive"
                    size="mini"
                    type="primary"
                    :class="[
                      'startbtn',
                      { livingbtn: scope.row.islive == true },
                    ]"
                    >{{ scope.row.islive ? "正在推流" : "开始推流" }}</el-button
                  >
                  <el-button
                    @click="deletefile(scope.row)"
                    :disabled="scope.row.islive"
                    size="mini"
                    type="primary"
                    class="phonebtn"
                    >删除</el-button
                  >
                  <!-- <el-button
                    @click="stoplive(scope.row)"
                    size="mini"
                    type="primary"
                    class="phonebtn"
                    >结束推流</el-button
                  > -->
                </template>
              </el-table-column>
            </com-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤3</p>
        <p class="step-content">打开左侧云手机直播App，开启直播</p>
      </div>
    </div>
  </div>
</template>
<script>
import TableBar from "./TableBar/index.vue";
import ComTable from "../../../../components/Table";
import navigation from "../../../../utils/navigation";
// import { filestoragelist } from "../../../../utils/tableList";
export default {
  name: "Unmanned",
  props: {
    query: {
      type: Object,
    },
    mainconnect: {
      type: Boolean,
    },
    stopmainlive: {
      type: Boolean,
    },
  },
  computed: {
    getobsrtmpurl() {
      return this.$store.getters.obsrtmpurl;
    },
  },
  watch: {
    getobsrtmpurl(val) {
      this.rtmpurl = val.slice(0, val.indexOf("live") + 5);
      this.rtmpcode = val.substring(val.indexOf("live/") + 5);
    },
    stopmainlive(val) {
      if (val) {
        var data = JSON.parse(JSON.stringify(this.tabledata));
        var tabledata = data.filter((item, index) => {
          item.islive = false;
          this.islive = false;
          return true;
        });
        this.tabledata = tabledata;
      }
    },
  },
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      activeName: "rtmp",
      tabledata: [],
      newtabledata: [],
      filestoragelist: [
        {
          prop: "FileName",
          label: "文件名",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      row: {},
      phonelist: [],
      region: "hk",
      loading: false,
      islive: false,
      rtmpurl: "",
      rtmpcode: "",
      urlloading: false,
    };
  },
  components: { ComTable, TableBar },
  activated() {
    // this.getufilelist();
  },
  mounted() {
    this.getufilelist();
  },
  methods: {
    touploadfile() {
      navigation.navigateTo({
        url: "storage",
      });
    },
    copyurl(e) {
      console.log(e);
      this.$message.success("复制成功");
    },
    copyerror(e) {
      // this.$message.error("复制");
      console.log(e);
    },

    getrtmpurl() {
      this.urlloading = true;
      if (!this.mainconnect) {
        this.$message.warning("请先完成步骤1的操作");
        this.urlloading = false;
        return;
      }
      setTimeout(() => {
        this.urlloading = false;
      }, 1000);
      this.$emit("startrtmpurl");
    },
    startlive(row) {
      if (!this.mainconnect) {
        this.$message.warning("请先完成步骤1的操作");
        return;
      }
      // if (this.islive) {
      //   this.$message.warning("请结束当前推流");
      //   return;
      // }
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        item.islive = false;
        if (item.FileId == row.FileId) {
          item.islive = true;
          this.islive = true;
        }
        return true;
      });
      this.tabledata = tabledata;
      this.getfilesurl(row).then((res) => {
        this.$emit("startunmannedlive", res);
      });
    },
    stoplive(row) {
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        if (item.FileId == row.FileId) {
          item.islive = false;
          this.islive = false;
        }
        return true;
      });
      this.tabledata = tabledata;
      this.$emit("stopunmannedlive");
    },
    getfilesurl(row) {
      let payload = {
        FileId: row.FileId,
        Expires: 48 * 3600,
      };
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("storage/getstorageurl", { ...payload })
          .then((res) => {
            if (res.RetCode == 0) {
              this.fileurl = res.SignedURL;
              resolve(res.SignedURL);
              // this.fileurl =
              //   "http://wumitech-storage-gd.internal-cn-gd-02.ufileos.com/test-2h.mp4?UCloudPublicKey=Cb9Y6TXChsvLcf35hHIAnzCGh3er1ph0dvCpzg8T9&Signature=%2F7z01XvXGQzaugVHzfJkBYlySAg%3D&Expires=1669396730";
            }
          });
      });
    },
    getufilelist() {
      this.loading = true;
      let payload = {
        StorageRegion: this.region,
        FileType: "FILE_LIVE",
      };
      this.$store
        .dispatch("storage/getufilelist", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.loading = false;
            this.tabledata = res.Files;
          } else {
            this.loading = false;
          }
        });
    },
    uploadfile() {
      this.$emit("uploadfile");
    },
    download() {
      var url = "https://wumitech-app-market-gd.cn-gd.ufileos.com/wm-tool.zip";
      var form = document.createElement("form");
      document.getElementById("download").appendChild(form);
      form.action = url;
      form.submit();
    },
    deletefile(row) {
      this.$emit("deletefile", row);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>