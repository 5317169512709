<template>
  <div class="humanmatrix-wrap">
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤1</p>
        <p class="step-content">左侧选择直播云手机</p>
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤2</p>
        <p class="step-content">选择矩阵直播云手机</p>
        <tree
          class="tree-wrap"
          ref="treefather"
          :props="props"
          :treedata="treedata"
          @treecheck="treecheck"
        />
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤3</p>
        <el-button
          v-if="!matrix"
          @click="startlivematrix"
          :loading="matrixloading"
          type="primary"
          size="small"
          class="livebtn"
          >开启矩阵直播</el-button
        >
        <el-button
          v-else
          @click="stoplivematrix"
          :loading="matrixloading"
          type="primary"
          size="small"
          class="livebtn"
          >结束矩阵直播</el-button
        >
        <p class="step-content">
          矩阵直播云手机可在群控管理页面，打开其他直播App同步直播
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Tree from "../../../../components/Tree";
export default {
  props: {
    mainconnect: {
      type: Boolean,
    },
    stopmainlive: {
      type: Boolean,
    },
    query: {
      type: Object,
    },
  },
  watch: {
    stopmainlive(val) {
      if (val) {
        this.stoplivematrix();
      }
    },
  },
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      phonelist: [],
      treedata: [],
      treedata2: [
        {
          id: 0,
          label: "未分组",
          isLeaf: false,
          disabled: false,
          children: [
            {
              label: "camera1",
              value: "camera1",
              phoneId: "camera1",
              uphoneName: "直播矩阵测试01",
              isLeaf: true,
              disabled: false,
            },
            {
              label: "camera2",
              value: "camera2",
              phoneId: "camera2",
              uphoneName: "直播矩阵测试02",
              isLeaf: true,
              disabled: false,
            },
            {
              label: "camera3",
              value: "camera3",
              phoneId: "camera3",
              uphoneName: "直播矩阵测试03",
              isLeaf: true,
              disabled: false,
            },
          ],
        },
      ],
      matrix: false,
      matrixloading: false,
    };
  },
  components: { Tree },
  methods: {
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);
      console.log(this.phonelist);
    },
    startlivematrix() {
      if (!this.mainconnect) {
        this.$message.warning("请先完成步骤1的操作");
        return;
      }
      if (!this.phonelist.length) {
        this.$message.warning("请先完成步骤2的操作");
        return;
      }
      this.disabledcontrol(true);
      this.$store.commit("group/setLivelist", { phonelist: this.phonelist });
      this.matrix = true;
    },
    stoplivematrix() {
      this.disabledcontrol(false);
      this.matrix = false;
      this.$store.commit("group/clearlivelist", { phonelist: this.phonelist });
    },
    disabledcontrol(boolean) {
      this.treedata.forEach((item, index) => {
        this.$set(item, "disabled", boolean);
        item.children.forEach((eitem, eindex) => {
          this.$set(eitem, "disabled", boolean);
        });
      });
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      // console.log(finnallist);
      // console.log(relist);
      // console.log(inlist);
      return finnallist;
    },
    getgrouplist() {
      let data = {
        Action: "ListBusinessGroup",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Infos);
          }
        });
      });
    },
    getphonelist() {
      let data = {
        Action: "DescribeUPhone",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        IsAll: true,
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.UPhones);
          }
        });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.UPhoneId;
                eitem["value"] = eitem.UPhoneId;
                eitem["phoneId"] = eitem.UPhoneId;
                eitem["uphoneName"] = eitem.UPhoneName;
                eitem["cityId"] = eitem.CityId;
                eitem["isLeaf"] = true;
                if (eitem.Tag == item.BusinessName) {
                  item["id"] = index;
                  item["label"] = item.BusinessName;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedata = filterlist;
        }
      );
    },
  },
  mounted() {
    // this.gettreedata();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>