<template>
  <el-dialog
    class="isliveguide"
    :visible.sync="isliveguideshow"
    @open="open"
    :before-close="closemodel"
  >
    <div class="liveguidebody">
          
          <el-card class="card">
          <p class="cardtitle">
              <span>直播指引</span>
          </p>
            <p class="tip-text">
              1、无人直播的整体流程：购买直播版云手机-> 直播文件管理页面下载视频转换工具处理视频-> 直播文件管理页面上传视频文件-> 云手机直播配置页面按步骤操作：步骤一选择直播版云手机(此时不开TK直播）-> 步骤二选择视频文件开始推流 ->步骤三在云手机内部打开TK开始直播
            </p>
            <p class="tip-text">
              2、直播文件管理页面：单用户最大免费存储空间为50GB，单个文件最大可到50GB；
            </p>
            <p class="tip-text">
              3、直播一帧一帧的如何解决？ 可能是未操作“下载视频转换工具处理视频”， 工具的使用说明在压缩包中；
            </p>
            <p class="tip-text">
              4、默认支持循环播放，最大时间48小时；支持MP4和FLV等格式视频；视频码率建议在4000Kbps~10000Kbps，码率越小则视频文件越小上传时间短但清晰度会下降；
            </p>
            <p class="tip-text">
              5、视频内容，<span class="stand-text">请勿使用画中画视频，请勿多天重复使用相同的视频文件，勿使用带有水印、很模糊的视频</span>，每封号一次会加长下一次封号时间；
            </p>
            <p class="tip-text">
              6、直播场景一机一号一IP，请勿将直播的云手机跟其他账号混用；
            </p>
            <p class="tip-text">
              7、账号方面要经过养号过程，未经过养号靠充粉丝的账号会无流量；账号不能在多国家频繁切换，这会让流量不精准；
            </p>
            <p class="tip-text">
              8、无人直播人气方面，注重在线人数、停留时间、直播间互动、分享数等指标，具体关注直播间加热产品；
            </p>
            <p class="tip-text">
              9、云手机真人直播：Web页面真人直播是横屏画面，采用竖屏画面请下载无觅安卓客户端使用。
            </p>
            
          </el-card>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: ["isliveguideshow", "title"],
  data() {
    return {};
  },
  methods: {
    open() {},
    closemodel() {
      this.$emit("closeliveguidemodel");
    },
  },
};
</script>
<style scoped lang="scss">
.isliveguide {
  ::v-deep .el-dialog {
    width: 620px;
  }
  ::v-deep .el-dialog__headerbtn {
    top: 12px;
    right: 9px;
  }
   ::v-deep .el-dialog__header {
    padding: 5px;
  }
  .cardtitle {
      text-align: center;
      height: 40px;
      font-size: 24px;
      font-weight: bold;
      color:black;
    }
  .card {
    width: 580px;
    height: 700px;
    overflow: hidden;
    
    .tip-text {
      font-size: 14px;
      color: rgb(151, 151, 151);
      line-height:32px;
      color: rgb(102,102,102);


    }
    .stand-text {
      font-size: 14px;
      color: black;
      font-weight:bold
      
    }
    
  }
}
</style>