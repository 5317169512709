<template>
  <div class="live-step">
    <keep-alive>
      <tradition v-if="modetype == 1" />
    </keep-alive>
    <keep-alive>
      <unmanned
        ref="unmanned"
        :mainconnect="mainconnect"
        :stopmainlive="stopmainlive"
        :query="query"
        v-if="modetype == 4"
        @startunmannedlive="startunmannedlive"
        @stopunmannedlive="stopunmannedlive"
        @startrtmpurl = "startrtmpurl"
        @uploadfile="uploadfile" 
        @getufilelist="getufilelist" 
        @deletefile="deletefile"
      />
    </keep-alive>
    <keep-alive>
      <human-matrix
        :mainconnect="mainconnect"
        :stopmainlive="stopmainlive"
        :query="query"
        v-if="modetype == 2"
      />
    </keep-alive>
    <keep-alive>
      <unmanned-matrix
        ref="unmannedmatrix"
        :query="query"
        v-if="modetype == 3"
      />
    </keep-alive>
  </div>
</template>
<script>
import Tradition from "./Tradition";
import Unmanned from "./Unmanned/index.vue";
import HumanMatrix from "./HumanMatrix/index.vue";
import UnmannedMatrix from "./UnmannedMatrix";

export default {
  name:'LiveStep',
  props: {
    mainconnect: {
      type: Boolean,
    },
    stopmainlive: {
      type: Boolean,
    },
    modetype: {
      type: Number,
      default: 1,
    },
    query: {
      type: Object,
    },
  },
  data() {
    return {
      showsubphone: false,
      row: {},
      livedata: {},
    };
  },
  components: {
    Tradition,
    Unmanned,
    HumanMatrix,
    UnmannedMatrix,
  },
  methods: {
    startunmannedlive(url) {
      this.$emit("startunmannedlive", url);
    },
    stopunmannedlive() {
      this.$emit("stopunmannedlive");
    },
    startrtmpurl(){
      this.$emit("startrtmpurl")
    },
    uploadfile(){
      this.$emit("uploadfile");
    },
    getufilelist(){
      this.$refs.unmanned.getufilelist();
    },
    deletefile(row) {
      this.$emit("deletefile", row);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>