<template>
  <div class="livestep-wrap">
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤1</p>
        <p class="step-content">左侧选择直播版云手机</p>
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤2</p>
        <p class="step-content">请允许当前浏览器使用摄像头和麦克风</p>
      </div>
    </div>
     <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤3</p>
        <p class="step-content">打开左侧云手机直播App，开启直播</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>