<template>
  <div class="table-bar">
    <el-button @click="renewlist" size="mini" type="primary" class="uploadbtn">
      刷新
    </el-button>
  </div>
</template>
<script>
export default {
  props: {
    region: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    renewlist() {
      this.$emit("getufilelist");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>