<template>
  <models
    :showmodel="showcreatelive"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmcreate"
    @closemodel="closeshowcreate"
    @open="open"
    class="livemodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">
        点击<span @click="createphone" class="warningspan">创建直播版</span
        >云手机，选择一台直播版云手机作为云手机直播配置主显示
      </p>
    </div>
    <tree
      ref="treefather"
      :props="props"
      :treedata="treedata"
      @treecheck="treecheck"
      class="auth-tree"
    />
  </models>
</template>
<script>
import Tree from "../../../components/Tree";
import Models from "../../../components/Models";
import navigation from "../../../utils/navigation";

export default {
  props: {
    query: {
      type: Object,
    },
    checkeditem: {
      type: Array,
    },
    showcreatelive: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      treedata: [],
      treedata2: [
        {
          id: 0,
          label: "未分组",
          isLeaf: false,
          children: [
            {
              label: "camera1",
              value: "camera1",
              phoneId: "camera1",
              uphoneName: "直播矩阵测试01",
              isLeaf: true,
            },
            {
              label: "camera2",
              value: "camera2",
              phoneId: "camera2",
              uphoneName: "直播矩阵测试02",
              isLeaf: true,
            },
            {
              label: "camera3",
              value: "camera3",
              phoneId: "camera3",
              uphoneName: "直播矩阵测试03",
              isLeaf: true,
            },
          ],
        },
      ],
      phonelist: [],
    };
  },
  components: { Models, Tree },
  methods: {
    open() {
      this.gettreedata();
    },
    createphone() {
      this.closeshowcreate();
      navigation.navigateTo({
        url: "createphone",
      });
    },
    confirmcreate() {
      this.$emit("confirmcreatelive", this.phonelist);
      this.closeshowcreate();
    },
    closeshowcreate() {
      this.treedata = [];
      this.phonelist = [];
      this.$emit("closeshowcreate");
    },
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);
      if (this.phonelist.length) {
        this.treedata.forEach((item, index) => {
          this.$set(item, "disabled", true);
          item.children.forEach((eitem, eindex) => {
            if (eitem !== this.phonelist[0]) {
              this.$set(eitem, "disabled", true);
            }
          });
        });
      } else {
        this.treedata.forEach((item, index) => {
          this.$set(item, "disabled", false);
          item.children.forEach((eitem, eindex) => {
            if (eitem !== this.phonelist[0]) {
              this.$set(eitem, "disabled", false);
            }
          });
        });
      }
      console.log(this.phonelist);
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      // console.log(finnallist);
      // console.log(relist);
      // console.log(inlist);
      return finnallist;
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Groups);
          }
        });
      });
    },
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("sub/getphonelist", { PhoneRegion: 1 })
          .then((res) => {
            if (res.RetCode == 0) {
              resolve(res.PhoneInfos);
            }
          });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.Id;
                eitem["value"] = eitem.Id;
                eitem["phoneId"] = eitem.OutId;
                eitem["uphoneName"] = eitem.Name;
                eitem["createTime"] = eitem.CreateTime;
                eitem["expireTime"] = eitem.ExpireTime;
                eitem["state"] = eitem.State;
                eitem["isLeaf"] = true;
                if (eitem.GroupId == item.Id && eitem.ProductId == "live") {
                  item["id"] = index;
                  item["label"] = item.Name;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedata = filterlist;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.livemodel {
  ::v-deep .el-dialog {
    width: 600px;
    height: 500px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 394px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .auth-tree > .el-tree-node > .el-tree-node__content .el-checkbox {
    display: none;
  }

  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
    .warningspan {
      color: rgb(56, 96, 244);
      border-bottom: 1px solid rgb(56, 96, 244);
      cursor: pointer;
    }
  }
}
</style>