<template>
  <div class="live-type">
    <div
      @click="changetype(4)"
      :class="[{ 'live-item-active': modetype == 4 }, 'live-item']"
    >
      <span>推流直播</span>
    </div>
    <div
      @click="changetype(1)"
      :class="[{ 'live-item-active': modetype == 1 }, 'live-item']"
    >
      <span>真人直播</span>
    </div>

    <!-- <div
      @click="changetype(2)"
      :class="[{ 'live-item-active': modetype == 2 }, 'live-item']"
    >
      <span>真人矩阵直播</span>
    </div> -->
    <!-- <div
      @click="changetype(3)"
      :class="[{ 'live-item-active': modetype == 3 }, 'live-item']"
    >
      <span>无人矩阵直播</span>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    modetype: {
      type: Number,
    },
  },
  data() {
    return {
      // livemode: 1,
    };
  },
  methods: {
    changetype(type) {
      this.$emit("changemode", type);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>