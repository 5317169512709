<template>
  <models
    :showmodel="showstoplive"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmstoplive"
    @closemodel="closestoplive"
    @open="open"
    class="stoplivemodel"
  >
    <div class="warningtitle">
      <p class="warningcontent">是否结束以下云手机直播？</p>
    </div>
    <com-table
      class="filelist"
      :headercellstyle="headercellstyle"
      :columns="filelist"
      :data="tabledata"
      tableheight="250"
      tablesize="small"
    />
    <p class="labletitle">直播文件名</p>
    <el-row>
      <el-col :span="10">
        <el-input size="small" :disabled="true" v-model="inputvalue"></el-input>
      </el-col>
    </el-row>
  </models>
</template>
<script>
import Models from "../../../components/Models";
import Tree from "../../../components/Tree";
import ComTable from "../../../components/Table";
export default {
  props: {
    showstoplive: {
      type: Boolean,
    },
    query: {
      type: Object,
    },
    row: {
      type: Object,
    },
    phonelist: {
      type: Array,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    row: {
      deep: true,
      handler(val) {
        this.inputvalue = val.FileName;
      },
    },
    phonelist: {
      deep: true,
      handler(val) {
        this.tabledata = val;
        console.log(val);
      },
    },
  },
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      inputvalue: "",
      tabledata: [],
      filelist: [
        {
          prop: "uphoneName",
          label: "云手机名称",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "phoneId",
          label: "云手机ID",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
    };
  },
  components: { Models, Tree, ComTable },
  methods: {
    open() {},
    closestoplive() {
      this.$emit("closestoplive");
    },
    confirmstoplive() {
      this.stoplive();
      this.closestoplive();
    },
    stoplive() {
      this.$store.commit("group/clearunmannedlist", {
        livelist: this.phonelist,
      });
      this.$emit("cleartablelist", this.row);
    },
  },
};
</script>
<style lang="scss" scoped>
.stoplivemodel {
  ::v-deep .el-dialog {
    width: 700px;
    height: 580px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 470px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  .labletitle {
    margin-bottom: 15px;
    color: rgb(56, 56, 56);
  }
  .filelist {
    margin-bottom: 25px;
  }
  .warningtitle {
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba(228, 229, 241);
    line-height: 40px;
    color: rgb(102, 102, 102);
    font-size: 14px;
    .warningcontent {
      margin-left: 20px;
    }
  }
}
</style>