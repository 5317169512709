<template>
  <div class="unmanned-wrap">
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤1</p>
        <p class="step-content">选择直播文件，开启推流</p>
        <table-bar :region="region" @getufilelist="getufilelist" />
        <com-table
          v-loading="loading"
          class="filestoragelist"
          :headercellstyle="headercellstyle"
          :columns="filestoragelist"
          :data="tabledata"
          tableheight="400"
        >
          <el-table-column
            align="center"
            header-align="center"
            slot="action"
            label="操作"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="scope.row.islive"
                @click="showphonemodel(scope.row)"
                size="mini"
                type="primary"
                :class="['startbtn', { livingbtn: scope.row.islive == true }]"
                >{{ scope.row.islive ? "正在推流" : "开始推流" }}</el-button
              >
              <el-button
                @click="showstopmodel(scope.row)"
                size="mini"
                type="primary"
                class="phonebtn"
                >结束推流</el-button
              >
            </template>
          </el-table-column>
        </com-table>
      </div>
    </div>
    <div class="content-wrap">
      <div class="step-left">
        <i class="iconfont icon-m-buzhou"></i>
      </div>
      <div class="step-right">
        <p class="step-title">步骤2</p>
        <p class="step-content">
          矩阵直播云手机可在云手机操作页面，打开云手机内部App开启直播
        </p>
      </div>
    </div>
    <sub-phone
      title="开启矩阵直播"
      :showsubphone="showsubphone"
      :query="query"
      :row="row"
      @settablelist="settablelist"
      @closeshowsubphone="closeshowsubphone"
    />
    <stop-live
      title="结束矩阵直播"
      :showstoplive="showstoplive"
      :row="row"
      :phonelist="phonelist"
      @cleartablelist="cleartablelist"
      @closestoplive="closestoplive"
    />
  </div>
</template>
<script>
import TableBar from "./TableBar/index.vue";
import ComTable from "../../../../components/Table";
import StopLive from "../../Models/StopLive.vue";
import SubPhone from "../../Models/SubPhone.vue";
// import { filestoragelist } from "../../../../utils/tableList";
export default {
  props: {
    query: {
      type: Object,
    },
  },
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      newtabledata: [],
      filestoragelist: [
        {
          prop: "FileName",
          label: "文件名",
          width: "100",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      row: {},
      phonelist: [],
      showsubphone: false,
      showstoplive: false,
      region: "hk",
      loading: false,
    };
  },
  components: { ComTable, StopLive, SubPhone, TableBar },
  activated() {
    // this.getufilelist();
  },
  mounted() {
    this.getufilelist();
  },
  methods: {
    getufilelist() {
      this.loading = true;
      let payload = {
        StorageRegion: this.region,
        FileType: "FILE_LIVE",
      };
      this.$store
        .dispatch("storage/getufilelist", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.loading = false;
            this.tabledata = res.Files;
          } else {
            this.loading = false;
          }
        });
    },
    showstopmodel(row) {
      // console.log(row)
      this.row = row;
      this.phonelist = row.livelist;
      this.showstoplive = !this.showstoplive;
    },
    closestoplive() {
      this.showstoplive = !this.showstoplive;
    },
    showphonemodel(row) {
      if (row.islive) {
        return;
      }
      this.row = row;
      this.showsubphone = !this.showsubphone;
      // console.log(row);
    },
    closeshowsubphone() {
      this.showsubphone = !this.showsubphone;
    },
    cleartablelist(row) {
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        if (item.FileId == row.FileId) {
          item.livelist = [];
          item.islive = false;
        }
        return true;
      });
      this.tabledata = tabledata;
    },
    settablelist(livedata) {
      var data = JSON.parse(JSON.stringify(this.tabledata));
      var tabledata = data.filter((item, index) => {
        if (item.FileId == livedata.fileId) {
          item["livelist"] = livedata.livelist;
          item["islive"] = true;
        }
        return true;
      });
      this.tabledata = tabledata;
      this.startlive(livedata);
    },
    startlive(livedata) {
      this.$store.commit("group/setunmannedlist", { ...livedata });
      console.log(livedata);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>