<template>
  <models
    :showmodel="showsubphone"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needcancel="needcancel"
    @confirm="confirmsubphone"
    @closemodel="closeshowsubphone"
    @open="open"
    class="subphonemodel"
  >
    <p class="labletitle">选择矩阵直播云手机</p>
    <tree
      class="tree"
      ref="treefather"
      :props="props"
      :treedata="treedata"
      @treecheck="treecheck"
    />
    <p class="labletitle">直播文件名</p>
    <el-row>
      <el-col :span="10">
        <el-input size="small" :disabled="true" v-model="inputvalue"></el-input>
      </el-col>
    </el-row>
  </models>
</template>
<script>
import Models from "../../../components/Models";
import Tree from "../../../components/Tree";
export default {
  props: {
    query: {
      type: Object,
    },
    row: {
      type: Object,
    },
    showsubphone: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needcancel: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    row(val) {
      this.inputvalue = val.FileName;
    },
  },
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      treedata: [],
      treedata2: [
        {
          id: 0,
          label: "未分组",
          isLeaf: false,
          children: [
            {
              label: "camera1",
              value: "camera1",
              phoneId: "camera1",
              uphoneName: "直播矩阵测试01",
              isLeaf: true,
            },
            {
              label: "camera2",
              value: "camera2",
              phoneId: "camera2",
              uphoneName: "直播矩阵测试02",
              isLeaf: true,
            },
            {
              label: "camera3",
              value: "camera3",
              phoneId: "camera3",
              uphoneName: "直播矩阵测试03",
              isLeaf: true,
            },
          ],
        },
      ],
      inputvalue: this.row.fileurl,
      phonelist: [],
      livelist: [],
    };
  },
  components: { Models, Tree },
  methods: {
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);
      // console.log(this.phonelist);
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      return finnallist;
    },
    open() {
      this.getfilesurl();
      this.gettreedata();
    },
    getfilesurl() {
      var Expires = Date.parse(new Date(new Date().setHours(new Date().getHours() + 48))) / 1000;
      let payload = {
        FileId: this.row.FileId,
        Expires: Expires,
      };
      this.$store
        .dispatch("storage/getstorageurl", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.fileurl = res.SignedURL;
          }
        });
    },
    confirmsubphone() {
      let data = [];
      this.phonelist.forEach((item, index) => {
        data.push({
          uphoneName: item.uphoneName,
          phoneId: item.phoneId,
          fileurl: this.fileurl,
        });
      });
      // this.livelist = data;
      this.$emit("settablelist", {
        livelist: data,
        fileId: this.row.FileId,
        fileurl: this.fileurl,
      });
      this.closeshowsubphone();
      // console.log(data);
    },
    closeshowsubphone() {
      this.treedata = [];
      this.phonelist = [];
      this.$emit("closeshowsubphone");
    },
    getgrouplist() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist").then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Groups);
          }
        });
      });
    },
    getphonelist() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("sub/getphonelist", { PhoneRegion: 1 })
          .then((res) => {
            if (res.RetCode == 0) {
              resolve(res.PhoneInfos);
            }
          });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.Id;
                eitem["value"] = eitem.Id;
                eitem["phoneId"] = eitem.OutId;
                eitem["uphoneName"] = eitem.Name;
                eitem["createTime"] = eitem.CreateTime;
                eitem["expireTime"] = eitem.ExpireTime;
                eitem["state"] = eitem.State;
                eitem["isLeaf"] = true;
                if (eitem.GroupId == item.Id && eitem.ProductId == "live") {
                  item["id"] = index;
                  item["label"] = item.Name;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedata = filterlist;
        }
      );
    },
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.subphonemodel {
  ::v-deep .el-dialog {
    width: 600px;
    height: 500px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 394px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  .labletitle {
    margin-bottom: 15px;
    color: rgb(56, 56, 56);
  }
  .tree {
    margin-bottom: 25px;
  }
}
</style>